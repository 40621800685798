<template>
  <section class="app-ecommerce-details">

    <!-- Content -->
    <b-card
    >
    
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
         

          <!-- Right: Product Details -->
          <b-col
            cols="12"
           
          >
  <b-form @submit.prevent="_addB">
      <br><br>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Label"
            label-for="h-label"
            label-cols-md="3"
          >
            <b-form-input
              v-model="banner.name"
              id="h-label"
              placeholder="Label"
              required
            />
          </b-form-group>
        </b-col>
         <b-col cols="12">
          <b-form-group
            label="Jours"
            label-for="h-days"
            label-cols-md="3"
          >
            <b-form-input
              v-model="banner.days"
              id="h-days"
              placeholder="Jours"
              required
            />
          </b-form-group>
        </b-col>
         <b-col cols="12">
          <b-form-group
            label="Lien"
            label-for="h-link"
            label-cols-md="3"
          >
            <b-form-input
              v-model="banner.link"
              id="h-link"
              placeholder="Lien"
              required
            />
          </b-form-group>
        </b-col>
           <b-col cols="12">
          <b-form-group
            label="Category"
            label-for="h-category"
            label-cols-md="3"
          >
          <v-select
              v-model="banner.categories"
              id="categoryList"
              placeholder="Category"
              :options="categories"
              required
            />
          </b-form-group>
        </b-col>
             <b-col cols="12">
          <b-form-group
            label="Sub-Category"
            label-for="h-sub-category"
            label-cols-md="3"
          >
          <v-select
              v-model="banner.subCategories"
              id="subcategoryList"
              placeholder="Sub-Category"
              :options="subcat"
              required
            />
          </b-form-group>
        </b-col>
        
       <b-col cols="12">
                <b-form-group
                            label="Image web"
                            label-for="h-image"
                            label-cols-md="3"
                        >
                            <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="_uploadimage(0)"
                    block
                    >
                    <span>Upload </span>
                    </b-button>
                </b-form-group>
        </b-col>
        
             <b-col cols="12">
                <b-form-group
                            label="Image mobile"
                            label-for="h-image"
                            label-cols-md="3"
                        >
                            <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="_uploadimage(1)"
                    block
                    >
                    <span>Upload</span>
                    </b-button>
                </b-form-group>
        </b-col>
       
        <!-- submit and reset -->
          <b-col cols="12">
          <b-form-group
      
            
          >
          <br>
           <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            block
          >
            Ajouter
          </b-button>
          </b-form-group>
        </b-col>
        
      </b-row>

    </b-form>
      
       

          

         
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
     <!-- <e-commerce-product-details-item-features />-->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
    <!--  <e-commerce-product-details-related-products /> -->
    </b-card>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BCard, BCardBody,BFormSelect, BRow, BFormFile, BCol, BImg, BCardText, BLink, BFormRadioGroup, BFormInvalidFeedback, BFormValidFeedback, BFormTextarea, BDropdown, BDropdownItem, BAlert,  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SubCategoryMixin from "@/mixins/subcategory.mixin";
import CategoryMixin from "@/mixins/category.mixin";
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import axios from 'axios'

export default {
  mixins: [CategoryMixin, SubCategoryMixin, heightTransition],
  props:["product"],
  directives: {
    Ripple,
  },
  components: {
    BFormFile,
    BFormRadioGroup, 
    BFormInvalidFeedback, 
    BFormValidFeedback,
    vSelect,
BFormSelect,
       BFormGroup, 
       BFormInput, 
       BFormCheckbox, 
       BForm, 
       BFormTextarea,
       
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

  },
  data () {
    return {
      cat : {},
      subcat : [],
        selected: 0,
        banner:{
            name:"",
            image:[],
            categories:"",
            subCategories:"",
            days:"",
            link:""
        }
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    this.initTrHeight()
    this.post.user = (JSON.parse(localStorage.user)).data
  },
  methods : {
    _uploadimage(arg) {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpseth2jc", upload_preset: "elicia" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.banner.image[arg] = this.publicId
            }
          }
        )
        .open();
    },
      _addB() {
   
      }     
  },
  watch: {
    cat: function() {
       this.subcat = this.cat.subCategories
       if(this.subcat.length>0)
     this.Osubcat = this.subcat[0].label
     else
     this.Osubcat = ''
    },
   
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
